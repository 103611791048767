import api from '@/apis/api'
/**
 * 生产数据统计相关api
 */

export class statisticApi extends api {

  getList ({pageInfo,condition}) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getStatisticDataList`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

  exportStatisticData(condition) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/exportStatisticData`,
      method: 'post',
      responseType: 'blob',
      data: condition
    })
  }

  getManufacturers(){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getManufacturers`,
      method: 'get'
    })
  }

  getDevModels(condition){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getModels`,
      method: 'post',
      data: condition
    })
  }
}

export default new statisticApi('statistic')

<template>
  <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange">
    <template #condition>
      <el-form-item required>
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          :disabled-date="disabledDate"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD HH:mm:ss"
          @change="changeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="listQuery.manufacturerId" placeholder="ODM制造商" clearable filterable @change="changeManufacturer">
          <el-option v-for="item in manufacturerList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="listQuery.modelId" placeholder="请选择产品型号" clearable filterable @change="changeModel">
          <el-option v-for="item in devModelList" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleStatistic">统计数据</el-button>
        <el-button type="primary" icon="el-icon-download" @click="exportStatistic">导出统计数据</el-button>
      </el-form-item>
    </template>
    <template #columns>
      <el-table-column label="ODM 制造商" align="center">
        <template #default="scope">
          {{scope.row.manufacturer.name}}
        </template>
      </el-table-column>
      <el-table-column v-if="listQuery.modelId !== undefined && listQuery.modelId !== ''" label="型号" align="center">
        <template #default="scope">
          {{scope.row.model.name}}
        </template>
      </el-table-column>
      <el-table-column label="统计时段" align="center">
        <span>{{this.listQuery.startTime}} - {{this.listQuery.endTime}}</span>
      </el-table-column>
      <el-table-column label="生产数量" align="center">
        <template #default="scope">
          <span>{{Number(scope.row.labelCount).toLocaleString()}}</span>
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>
<script>
  import ListConditionTemplate from "@/components/templates/listConditionTemplate";
  import statisticApi from "@/apis/data/statistic";
  import {ElMessage} from "element-plus";
  export default {
    components: {ListConditionTemplate},
    data() {
      return {
        total: 0,
        rows: [],
        listQuery: {
          startTime: '',
          endTime: '',
          manufacturerId: '',
          modelId: '',
        },
        modelQuery: {
          manufacturerId: '',
        },
        dateRange: '',
        manufacturerList: [],
        devModelList: [],
        aLLModel: [],
      }
    },
    created() {
      this.getManufacturers()
      this.getDevModels()
    },
    methods: {
      disabledDate(time) {
        const nowDate = new Date();
        return time.getTime() > nowDate.getTime();
      },
      changeTime(data) {
        if (data != null && data.length === 2) {
          this.listQuery.startTime = data[0]
          const eDate = new Date(data[1])
          // 将时间设置为当天的 23:59:59
          eDate.setHours(23);
          eDate.setMinutes(59);
          eDate.setSeconds(59);
          this.listQuery.endTime = this.dateFormatter(eDate)
        }else {
          this.listQuery.startTime = ''
          this.listQuery.endTime = ''
        }
        console.log(this.listQuery)
      },
      getManufacturers() {
        statisticApi.getManufacturers().then(res => {
          this.manufacturerList = res.data
        })
      },
      changeManufacturer() {
        if (this.listQuery?.manufacturerId ?? 0) {
          this.listQuery.modelId = ''
          this.filterModel(this.listQuery.manufacturerId)
        }else {
          this.devModelList = this.aLLModel
        }
        this.getList()
      },
      changeModel() {
        this.getList()
      },
      filterModel(manufacturerId) {
        this.devModelList = this.aLLModel.filter(item => {
          if (item.manufacturers !== null && item.manufacturers.length > 0) {
            for (const manufacturer of item.manufacturers) {
              if (manufacturer.id === manufacturerId) {
                return item
              }
            }
          }
        })
      },
      getDevModels() {
        statisticApi.getDevModels(this.modelQuery).then(res => {
          this.aLLModel = res.data
          this.devModelList = this.aLLModel
        })
      },
      getList(pageInfo) {
        this.total = 0
        this.rows = []
        statisticApi.getList({pageInfo: pageInfo,condition: {...this.listQuery}}).then(res => {
          this.rows = res.data
          this.total = res.data.total
        })
      },
      handlePageInfoChange (pageInfo) {
        this.getList(pageInfo)
      },
      handleStatistic() {
        if (!this.listQuery?.startTime ?? 0) {
          ElMessage.warning('请选择日期')
          return
        }
        this.getList()
      },
      exportStatistic() {
        if (this.total <= 0) {
          ElMessage.warning('统计数据为空')
          return
        }
        statisticApi.exportStatisticData(this.listQuery).then(res => {
          const excelName = '生产数据统计.xlsx'
          saveAs(res.data,excelName)
        }).catch(res => {
          ElMessage.error("无法下载")
        })
      },
      dateFormatter(date) {
        date = (date ? new Date(date) : new Date)
        const formatter = 'YYYY-MM-DD HH:mm:ss'
        const Y = date.getFullYear() + '',
            M = date.getMonth() + 1,
            D = date.getDate(),
            H = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds()
        return formatter.replace(/YYYY|yyyy/g, Y)
            .replace(/YY|yy/g, Y.substr(2, 2))
            .replace(/MM/g, (M < 10 ? '0' : '') + M)
            .replace(/DD/g, (D < 10 ? '0' : '') + D)
            .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
            .replace(/mm/g, (m < 10 ? '0' : '') + m)
            .replace(/ss/g, (s < 10 ? '0' : '') + s)
      },
    }
  }
</script>
